import { useRouteLoaderData } from "@remix-run/react";
import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";
import type { loader } from "./routes/_application";
import { useTrpc } from "./utils/TrpcClientProvider";

type ContextType =
  | {
      loading: false;
      flags: Record<
        string,
        | {
            value: boolean;
            isDefaultValue: boolean;
          }
        | undefined
      >;
    }
  | {
      loading: true;
      flags: undefined;
    };

const FeatureFlagsContext = createContext<ContextType>({
  flags: undefined,
  loading: true,
});

export const FeatureFlagsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const t = useTrpc();

  const rootData = useRouteLoaderData<typeof loader>("routes/_application");

  const initFlags = rootData?.flags;

  const { data: flags, isLoading } = t.getFeatureFlags.useQuery(
    {},
    {
      refetchInterval: 10_000,
    }
  );

  return (
    <FeatureFlagsContext.Provider
      value={
        flags && !isLoading
          ? {
              loading: false,
              flags,
            }
          : initFlags
          ? {
              loading: false,
              flags: initFlags,
            }
          : { loading: true, flags: undefined }
      }
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
