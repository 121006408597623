import * as CookieConsent from "vanilla-cookieconsent";

export const setupCookieConsent = ({
  ifAnalyticsAccepted,
  hideFromBots = true,
}: {
  ifAnalyticsAccepted: () => void;
  hideFromBots: boolean;
}) => {
  CookieConsent.run({
    onConsent: () => {
      if (CookieConsent.acceptedCategory("analytics")) {
        ifAnalyticsAccepted();
      }
    },
    guiOptions: {
      consentModal: {
        layout: "bar",
        position: "bottom",
        equalWeightButtons: false,
        flipButtons: false,
      },
      preferencesModal: {
        layout: "bar",
        position: "left",
        equalWeightButtons: false,
        flipButtons: false,
      },
    },
    categories: {
      necessary: {
        readOnly: true,
      },
      analytics: {},
    },
    hideFromBots,
    language: {
      default: "fr",
      autoDetect: "browser",
      translations: {
        en: {
          consentModal: {
            title: "Cookie Consent",
            description:
              "We use cookies to ensure you get the best experience on our website. Please choose your preferences for cookies below.",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            showPreferencesBtn: "Manage preferences",
            footer:
              '<a href="https://www.switchgrid.tech/cgu">Terms and conditions (in French language)</a>',
          },
          preferencesModal: {
            title: "Consent Preferences Center",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            savePreferencesBtn: "Save preferences",
            closeIconLabel: "Close modal",
            serviceCounterLabel: "Service|Services",
            sections: [
              {
                title: "Cookie Usage",
                description:
                  "Switchgrid uses cookies to ensure you get the best experience on our website. Please choose your preferences for cookies below.",
              },
              {
                title:
                  'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                description:
                  "We use these cookies for user authentication exclusively.",
                linkedCategory: "necessary",
              },
              {
                title: "Analytics Cookies",
                description:
                  "We use these cookies to collect data about your visit to our website. The services we use are <a class='cc__link' href='https://policies.google.com/privacy?hl=en-US' target='_blank'>Google Analytics</a> and <a class='cc__link' href='https://www.fullstory.com/resources/fullstory-gdpr-you/' target='_blank'>FullStory</a>. The FullStory data is deleted after 30 days.",
                linkedCategory: "analytics",
              },
              {
                title: "More information",
                description:
                  'For any query in relation to our policy on cookies and your choices, please contact us at <a class="cc__link" href="mailto:contact@switchgrid.tech">contact@switchgrid.tech</a>.',
              },
            ],
          },
        },
        fr: {
          consentModal: {
            title: "Cookies et consentement",
            description:
              "Switchgrid utilise des cookies pour vous garantir la meilleure expérience sur notre site web. Veuillez choisir vos préférences en matière de cookies ci-dessous.",
            acceptAllBtn: "Accepter tout",
            acceptNecessaryBtn: "Tout rejeter",
            showPreferencesBtn: "Gérer les préférences",
            footer:
              '<a href="https://www.switchgrid.tech/cgu">Conditions Générales d‘utilisation</a>',
          },
          preferencesModal: {
            title: "Préférences de cookies",
            acceptAllBtn: "Accepter tout",
            acceptNecessaryBtn: "Tout rejeter",
            savePreferencesBtn: "Sauver les préférences",
            closeIconLabel: "Fermer la modale",
            serviceCounterLabel: "Services",
            sections: [
              {
                title: "Utilisation de Cookies",
                description:
                  "Switchgrid utilise des cookies pour vous garantir la meilleure expérience sur notre site web. Veuillez choisir vos préférences en matière de cookies ci-dessous.",
              },
              {
                title:
                  'Cookies Strictement Nécessaires <span class="pm__badge">Toujours Activé</span>',
                description:
                  "Nous utilisons ces cookies pour l'authentification des utilisateurs uniquement.",
                linkedCategory: "necessary",
              },
              {
                title: "Cookies Analytiques",
                description:
                  "Nous utilisons ces cookies pour collecter des données sur votre visite sur notre site web. Les services que nous utilisons sont <a class='cc__link' href='https://policies.google.com/privacy?hl=en-US' target='_blank'>Google Analytics</a> et <a class='cc__link' href='https://www.fullstory.com/resources/fullstory-gdpr-you/' target='_blank'>FullStory</a>. Les données FullStory sont supprimées après 30 jours.",
                linkedCategory: "analytics",
              },
              {
                title: "Plus d'informations",
                description:
                  'Pour toute question concernant notre politique en matière de cookies et vos choix, veuillez nous contacter à l\'adresse <a class="cc__link" href="mailto:contact@switchgrid.tech">contact@switchgrid.tech</a>.',
              },
            ],
          },
        },
      },
    },
  });
};
